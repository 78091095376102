import { useState, useMemo, useCallback, useRef, useEffect, memo } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import PropTypes from 'prop-types';
import './DatePicker.css';

export const DatePicker = memo((props) => {
  const {
    allowEdit,
    cssClass,
    format,
    label,
    showClearButton,
    value,
    min,
    max,
    onChange,
    enabled,
    ...rest
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        marginRight: '10px',
      }}
    >
      {label && (
        <div
          style={{
            fontSize: '14px',
            color: '#6D6D73',
            marginBottom: '5px',
            marginRight: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </div>
      )}
      <div className="view-schedule-date-picker-wrapper">
        <DatePickerComponent
          value={value}
          format={format}
          showClearButton={showClearButton}
          allowEdit={allowEdit}
          strictMode={true}
          min={min}
          max={max}
          onChange={onChange}
          cssClass={cssClass}
          enabled={enabled}
          {...rest}
        />
      </div>
    </div>
  );
}
)
DatePicker.propTypes = {
  ref: PropTypes.object,
  value: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  showClearButton: PropTypes.bool,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  cssClass: PropTypes.string,
};
