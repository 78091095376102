import React, { useEffect, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { closeIcon, magnifyingGlassSmIcon } from '../../assets';
import styles from './styles.module.css';

const Searchbar = memo(({ onSearch, width, smallInput }) => {
    const [searchKey, setSearchKey] = useState('');
    const [showClearSearch, setShowClearSearch] = useState(false);
    const [saveSearchKey, setSaveSearchKey] = useState('');
    const searchRef = useRef(null);

    const onChangeSearchKey = (e) => {
        setSearchKey(e.target.value);
        setShowClearSearch(false);
    };

    const handleOnSearch = () => {
        setSaveSearchKey(searchKey);
        onSearch(searchKey);
        const hasSearchKey = searchKey.length > 0;
        setShowClearSearch(hasSearchKey);
    };

    const onClearSearch = () => {
        setSaveSearchKey('');
        setSearchKey('');
        onSearch('');
        setShowClearSearch(false);
    };

    const triggerOnSearch = (e) => {
        if (e.key === 'Enter') handleOnSearch();
    };

    const triggerOnClearSearch = (e) => {
        if (e.key === 'Enter') onClearSearch();
    };

    useEffect(() => {
        const backdropClicked = (e) => {
            if (!searchRef.current?.contains(e.target)) {
                setSearchKey(saveSearchKey);
                const shouldHideClearSearch = saveSearchKey?.length > 0;
                setShowClearSearch(shouldHideClearSearch);
            }
        };

        document.addEventListener('mousedown', backdropClicked);
        return () => document.removeEventListener('mousedown', backdropClicked);
    }, [searchRef, saveSearchKey]);

    return (
        <div
            ref={searchRef}
            className="d-flex"
            style={{ width: width || '100%' }}
        >
            <input
                type="text"
                style={{ textIndent: '5px' }}
                className={`${styles.searchInput} ${smallInput ? styles.smallInput : ''}`}
                placeholder="Search"
                value={searchKey}
                onChange={onChangeSearchKey}
                onKeyDown={triggerOnSearch}
            />

            <div className={`${styles.searchIconWrapper} ${smallInput ? styles.smallInput : ''}`}>
                <img
                    className={styles.closeIcon}
                    src={closeIcon}
                    alt="CloseIcon"
                    onClick={onClearSearch}
                    onKeyDown={triggerOnClearSearch}
                    tabIndex={0}
                    hidden={!showClearSearch}
                />

                <img
                    className={styles.searchIcon}
                    style={{ pointerEvents: searchKey.length === 0 ? 'none' : null }}
                    src={magnifyingGlassSmIcon}
                    alt="SearchIcon"
                    tabIndex={0}
                    onClick={handleOnSearch}
                    onKeyDown={triggerOnSearch}
                />
            </div>
        </div>
    );
});

Searchbar.propTypes = {
    hide: PropTypes.bool,
    onSearch: PropTypes.func,
    width: PropTypes.string,
    smallInput: PropTypes.bool,
};

Searchbar.defaultProps = {
    hide: true,
    width: '',
    onSearch: () => { },
    smallInput: false,
};

export default Searchbar;