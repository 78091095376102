import React, { useCallback, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Button from '../button/Button';

const Header = memo(({
  title,
  buttonText,
  onClickButton,
  childComponent,
}) => {
  const noButton = typeof onClickButton !== 'function' && !buttonText;

  const Title = () => {
    // Function to render the title based on its type
    const renderTitle = () => {
      if (typeof title === 'string') {
        return title; // Render the string directly
      }
      if (typeof title === 'function') {
        return title(); // Call the function to get JSX
      }
      return title; // Assume it's JSX and render it directly
    };

    return (
      <div className={styles.title} title={typeof title === 'string' ? title : undefined}>
        {renderTitle()}
      </div>
    );
  };

  return (
    <div className={styles.banner}>
      {/* <div className={styles.title} title={title}>{title}</div> */}
      {Title()}
      <div className={styles.actionWrapper}>
        <div className={styles.floatRight}>
          {!noButton && <Button text={buttonText} onClick={onClickButton} />}
          {childComponent}
        </div>
      </div>
    </div>
  );
})

Header.propTypes = {
  componentName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
  childComponent: PropTypes.node,
};

Header.defaultProps = {
  componentName: '',
  buttonText: '',
  onClickButton: null,
  childComponent: null
};

export default Header;
