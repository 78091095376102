import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Button = ({
  text,
  size,
  defaultBtn,
  disabled,
  onClick,
  style,
  caretOn,
  title,
  isLoading,
  type,
  hidden,
}) => {
  const largeButton = size === 'lg' && styles.buttonLg;
  const mediumButton = size === 'md' && styles.buttonMd;
  const smallButton = size === 'sm' && styles.buttonSm;
  const buttonWithCaret = caretOn && styles.buttonWithCaret;
  const isDefaultButton = defaultBtn ? styles.defaultButton : styles.button;
  return (
    <button
      className={`${isDefaultButton} ${largeButton} ${mediumButton} ${smallButton} ${buttonWithCaret}`}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled || isLoading}
      title={title}
      type={type}
      hidden={hidden}
    >
      <span className={caretOn ? styles.buttonText : null}>{text}</span>
      {isLoading && (
        <i
          className="fas fa-circle-notch fa-spin"
          style={{ marginLeft: '5px' }}
        />
      )}
      {caretOn && <span className={styles.caretIcon} />}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  defaultBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape(),
  caretOn: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  hidden: PropTypes.bool,
};

Button.defaultProps = {
  size: 'md',
  text: '',
  defaultBtn: false,
  disabled: false,
  onClick: null,
  style: null,
  caretOn: false,
  title: null,
  isLoading: false,
  type: 'button',
  hidden: false,
};

export default Button;
