import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from '../../channel-profiles/business-rules/modals/Modal.module.css';
import Button from 'shared/components/button/Button';
import { debounce } from '@syncfusion/ej2-base';

const DeleteModalPrompt = ({
    show, onHide, onProceed, message
}) => {
    const debouncedHandleClick = debounce(onProceed, 300);
    return (
        <Modal show={show} centered size="md">
            <div className={styles.body} style={{ textAlign: 'center' }}>
                <div>
                    <p className={styles.description}>
                        {message ?? `This pattern will be deleted. This action cannot be undone and all data associated with this pattern will be permanently removed.`}
                    </p>

                    <p className={styles.confirmationText}>
                        Are you sure you want to delete this pattern?
                    </p>
                </div>

                <div style={{ marginTop: '30px' }}>

                    <Button
                        text="Cancel"
                        defaultBtn
                        onClick={onHide}
                    />
                    <Button text="Delete" onClick={debouncedHandleClick} />
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModalPrompt