
import React, { memo, useEffect } from 'react';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { Calendar } from '../'

export const ContextMenu = memo((props) => {
  const {
    handleContextMenuOpen,
    handleContextMenuSelect,
    contextMenuObj,
  } = props;

  const { ContextMenuList } = Calendar;

  return (
    <ContextMenuComponent
      id="overviewContextMenu"
      cssClass="sf-theme-op2mise"
      ref={contextMenuObj}
      target=".e-schedule"
      items={ContextMenuList}
      beforeOpen={handleContextMenuOpen}
      select={handleContextMenuSelect}
    />
  )
});